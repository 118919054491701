.reset-page {
  -ms-flex-align: center;
  align-items: center;
  background-color: #e9ecef;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -ms-flex-pack: center;
  justify-content: center;
}

.reset-box {
  width: 360px;
}

.reset-box .card,
.register-box .card {
  margin-bottom: 0;
}
.reset-box .card-primary.card-outline {
  border-top: 3px solid rgba(250, 65, 65, 1);
}
.reset-box .card {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: black;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.reset-box .card-header:first-child {
  border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0;
}

.reset-box .card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1.85rem 1.25rem 0rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.reset-box .card-header:first-child {
  border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0;
}

.reset-box .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.reset-box-msg {
  margin: 0;
  padding: 0 20px 20px;
  text-align: center;
  color: white;
}

.social-group-text {
  margin: 0;
  padding: 30px 0px 0px;
  text-align: center;
  color: white;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.social-auth-links {
  margin: 10px 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-primary {
  color: #fff !important;
  border-style: solid !important;
  background-color: rgba(250, 65, 65, 1) !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.35rem 0.75rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.social-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.social-group-box {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
}

.social-box-item {
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
}

.input-group-append {
  margin-left: -1px;
  display: flex;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(250, 65, 65, 1);
  border: 1px solid rgba(250, 65, 65, 1);
  border-radius: 0.25rem;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.reset-link-sended {
  color: white;
  text-align: center;
}
